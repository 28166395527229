import React from 'react';
import { useIntl } from 'react-intl';
import styles from './checkbox.module.scss';

export default function CoursesSelection({ title, description, price }) {
  const intl = useIntl();
  return(
    <div className={styles.courses}>
      <div className={styles.coursesLeft}>
        <div className={styles.coursesTitle}>{title}</div>
        <div className={styles.coursesDescription}>{description}</div>
      </div>
      <div className={styles.coursesRight}>
        <div className={styles.coursesTitle}>{price}</div>
        <div className={styles.coursesDescription}>{intl.formatMessage({ id: "quoteCoursePerPerson" })}</div>
      </div>
    </div>
  );
}

import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import CheckBox from '../Form/checkbox';
import CoursesSelection from '../Form/courses-selection';

export default function Courses({ set, courses }) {
  const intl = useIntl();
  const nextStepPath = '/get-quote/dietary-restrictions';

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteCoursesHeadline" })}</FormHeadline>

      <Link to={nextStepPath}>
        <CheckBox checked={courses === 4} handleClick={() => set({courses: 4})}>
          <CoursesSelection title={'Temptation'} description={intl.formatMessage({ id: "quoteCoursesOptionOne" })} price={'€155'} />
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={courses === 7} handleClick={() => set({courses: 7})}>
          <CoursesSelection title={'Exclusive'} description={intl.formatMessage({ id: "quoteCoursesOptionTwo" })} price={'€225'} />
        </CheckBox>
      </Link>
    </FormContainer>
  );
}

import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import Courses from '../../components/GetQuoteSteps/courses';
import QuoteContext from '../../context/quote-context';

export default function QuoteCoursesPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <Courses set={quote.set} courses={quote.courses} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
